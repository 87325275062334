import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { RegisterService } from 'src/app/register/register.service';
import { NotMatch } from '../services/commanservice';

import { DataglobalUse } from '../configurationData';
import { UserModel } from '../models/UserModel';
import { DialogComponent } from '../dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
    selector: 'password-maintenance',
    templateUrl: './password-maintenance.component.html',
    styleUrls: ['./password-maintenance.component.css']
})
export class PasswordMaintenanceComponent implements OnInit {
    @Output() isThirdFormGroupValue: EventEmitter<any> = new EventEmitter();
    @Output() isError: EventEmitter<any> = new EventEmitter();
    @Output() errorMessage: EventEmitter<any> = new EventEmitter();
    @Input() secondForm: any;
    @Input() hideBackBtn: boolean;
    siteKey = DataglobalUse.siteKey;
    secretKey = DataglobalUse.secretKey;
    thirdFormGroup?: UntypedFormGroup;
    passwordQuestions: any;
    content: any;
    theme: any;
    type: any;
    size: any;
    lang: any;
    showSpinner: boolean = false;
    isFormLoad: boolean = false;
    passwordQuestionsFor2: any;
    passwordQuestionsFor1: any;

    constructor(public dialog: MatDialog, private registerService: RegisterService, @Inject(SESSION_STORAGE) private storage: StorageService, private _formBuilder: UntypedFormBuilder) //private sanitizer: DomSanitizer,
    { }

    ngOnInit(): void {
        this.registerService.getTermsAndConditions(this.storage.get('instanceId')).subscribe(
            (data: any) => {
                //this.content = this.sanitizer.bypassSecurityTrustHtml(data.tandc);
                this.isFormLoad = true;
                this.passwordQuestions = data.questions;
                this.passwordQuestionsFor1 = this.passwordQuestions;
            },
            (error: any) => {
            }
        );

        this.thirdFormGroup = this._formBuilder.group({
            question1: ['', Validators.required],
            answer1: ['', [Validators.required, Validators.maxLength(200)]], // Validators.pattern('[a-zA-Z0-9]*$')
            question2: ['', Validators.required],
            answer2: ['', [Validators.required, Validators.maxLength(200)]], // Validators.pattern('[a-zA-Z0-9]*$')
            recaptcha: ['', Validators.required]
        }, {
            validator: [
                NotMatch('question1', 'question2'),
            ]
        });

    }

    public hasError = (formName: string, controlName: string, errorName: string) => {

        return this.thirdFormGroup?.controls[controlName].hasError(errorName);
    }

    public loginPage = () => {
        const a = 1;
        const dialogRef = this.dialog.open(DialogComponent, { data: a });
    }


    public handleSuccess = (data: any) => {

    }

    public handleReset = () => {

    }

    public handleExpire = () => {

    }

    public handleLoad = () => {

    }

    selectedQuestionFor1(value: any) {
        this.passwordQuestionsFor2 = this.passwordQuestions.filter(x => x.passwordQuestionID != value);
    }
    selectedQuestionFor2(value: any) {
        this.passwordQuestionsFor1 = this.passwordQuestions.filter(x => x.passwordQuestionID != value);
    }

    public submit = (thirdForm: any) => {
        this.showSpinner = true;
        var model = new UserModel();
        model.InstanceId = +this.storage.get('instanceId');
        model.EmailAddress = this.secondForm.emailAddress;
        model.Password = this.secondForm.password;
        model.EmailAsUsername = this.secondForm.username;
        model.PasswordQuestionId = thirdForm.question1;
        model.PasswordAnswer = thirdForm.answer1;
        model.PasswordQuestion2Id = thirdForm.question2;
        model.PasswordAnswer2 = thirdForm.answer2;
        model.FirstName = this.secondForm.firstName;
        model.MiddleName = this.secondForm.middleName;
        model.LastName = this.secondForm.lastName;
        model.PhoneNumber = this.secondForm.phoneNumber;
        if (localStorage.getItem('isExternalUser') == 'true') {
            model.IsExternalUser = true;
            model.ApplicationID = +localStorage.getItem('ApplicationId');
        }

        this.isThirdFormGroupValue.emit();
        // model.Termandcondition = firstForm.termandcondition
        this.registerService.registerUser(model).subscribe(
            (res: any) => {
                this.getregisterusersuccess(res.success)
            },
            (err: any) => {
                this.registerUserError(err)
                //this.errorMessage.emit(err.error);
            });
    }

    getregisterusersuccess(res: string) {
        if (localStorage.getItem('isExternalUser') == 'true')
            localStorage.setItem('DirectUserUserID', res.substring(res.indexOf('=') + 1, res.length));
        this.showSpinner = false;
        this.isError.emit(false);
        //this.snackbar.openSnackBar(res.success, 1);
    }

    registerUserError(res: any) {
        this.isError.emit(true);
        this.errorMessage.emit(res.error);
        //this.snackbar.openSnackBar(res.error, 2);
    }



}
