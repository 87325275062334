import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DataglobalUse } from '../configurationData';
import { DialogComponent } from '../dialog/dialog.component';
import { CommanService, MustMatch } from '../services/commanservice';


@Component({
    selector: 'user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {

    @Output() isSecondFormGroupValue: EventEmitter<any> = new EventEmitter();
    @Input() hideBackBtn: boolean;

    showDetails: boolean = true;
    secondFormGroup?: UntypedFormGroup;
    isEditable = true;
    readonly emailPattern = DataglobalUse.emailPattern;
    readonly passwordPattern = DataglobalUse.passwordPattern;
    showPassword: boolean = false;

    constructor(private _formBuilder: UntypedFormBuilder, private commonService: CommanService, public dialog: MatDialog) { }

    ngOnInit(): void {

        this.secondFormGroup = this._formBuilder.group({
            username: ['Email', Validators.required],
            emailAddress: ['', [Validators.required, Validators.maxLength(200), Validators.pattern(this.emailPattern)]],
            confirmEmailAddress: ['', [Validators.required,]],
            password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(25), Validators.pattern(this.passwordPattern)]],
            confirmPassword: ['', [Validators.required]],
            firstName: ['', Validators.required],
            middleName: [''],
            lastName: ['', Validators.required],
            phoneNumber: ['', Validators.required],
        }, {
            validator: [
                MustMatch('emailAddress', 'confirmEmailAddress'),
                MustMatch('password', 'confirmPassword'),
                this.commonService.CheckEmailExist('emailAddress')
                //NoWhitespaceValidator('password'),
            ]
        });

        if (localStorage.getItem('isExternalUser') == 'true') {
            this.commonService.getPersonDetailsForRegistration().subscribe(
                (data: any) => {
                    if (data) {
                        this.secondFormGroup.controls['firstName'].setValue(data.firstName);
                        this.secondFormGroup.controls['middleName'].setValue(data.middleName);
                        this.secondFormGroup.controls['lastName'].setValue(data.lastName);
                        this.secondFormGroup.controls['emailAddress'].setValue(data.email);
                        this.secondFormGroup.controls['confirmEmailAddress'].setValue(data.email);
                        this.secondFormGroup.controls['phoneNumber'].setValue(data.phoneMobile);
                        
                        //this.secondFormGroup.updateValueAndValidity();
                        this.secondFormGroup.controls['emailAddress'].markAsTouched();
                        // if (!this.secondFormGroup.controls['emailAddress'].valid)
                        //     this.secondFormGroup.controls['emailAddress'].markAsTouched();
                    }
                },
                (error: any) => {

                }
            );
        }
    }


    onlyNumbers(event: any) {

        return this.commonService.OnlyNumbers(event);
    }

    commSelectionChange(value: any) {
        if (value == "Random") {
            this.secondFormGroup = this._formBuilder.group({
                username: ['Random', Validators.required],
                emailAddress: [this.secondFormGroup?.value.emailAddress, [Validators.required, Validators.maxLength(200), Validators.pattern(this.emailPattern)]],
                //emailAddress: [this.secondFormGroup.controls['emailAddress'].value, [Validators.required, Validators.maxLength(200), Validators.pattern(this.emailPattern)]],
                confirmEmailAddress: [this.secondFormGroup?.value.confirmEmailAddress, [Validators.required,]],
                password: [this.secondFormGroup?.value.password, [Validators.required, Validators.minLength(8), Validators.maxLength(25), Validators.pattern(this.passwordPattern)]],
                confirmPassword: [this.secondFormGroup?.value.confirmPassword, [Validators.required]],
                firstName: [this.secondFormGroup?.value.firstName, Validators.required],
                middleName: [this.secondFormGroup?.value.middleName],
                lastName: [this.secondFormGroup?.value.lastName, Validators.required],
                phoneNumber: [this.secondFormGroup?.value.phoneNumber, Validators.required]
            }, {
                validator: [
                    MustMatch('emailAddress', 'confirmEmailAddress'),
                    MustMatch('password', 'confirmPassword')
                    //NoWhitespaceValidator('password'),
                ]
            });
        }
        else {
            this.secondFormGroup = this._formBuilder.group({
                username: ['Email', Validators.required],
                emailAddress: [this.secondFormGroup?.value.emailAddress, [Validators.required, Validators.maxLength(200), Validators.pattern(this.emailPattern)]],
                //emailAddress: [this.secondFormGroup.controls['emailAddress'].value, [Validators.required, Validators.maxLength(200), Validators.pattern(this.emailPattern)]],
                confirmEmailAddress: [this.secondFormGroup?.value.confirmEmailAddress, [Validators.required,]],
                password: [this.secondFormGroup?.value.password, [Validators.required, Validators.minLength(8), Validators.maxLength(25), Validators.pattern(this.passwordPattern)]],
                confirmPassword: [this.secondFormGroup?.value.confirmPassword, [Validators.required]],
                firstName: [this.secondFormGroup?.value.firstName, Validators.required],
                middleName: [this.secondFormGroup?.value.middleName],
                lastName: [this.secondFormGroup?.value.lastName, Validators.required],
                phoneNumber: [this.secondFormGroup?.value.phoneNumber, Validators.required]
            }, {
                validator: [
                    MustMatch('emailAddress', 'confirmEmailAddress'),
                    MustMatch('password', 'confirmPassword'),
                    this.commonService.CheckEmailExist('emailAddress')
                    //NoWhitespaceValidator('password'),
                ]
            });
        }
    }

    public hasError = (formName: string, controlName: string, errorName: string) => {
        if (formName == "secondFormGroup")
            return this.secondFormGroup?.controls[controlName].hasError(errorName);

    }

    public shoPass = () => {
        this.showPassword = !this.showPassword;
    }

    public onStrengthChanged = (data: any) => {

    }

    public loginPage = () => {

        const a = 1;
        const dialogRef = this.dialog.open(DialogComponent, { data: a });
    }


    secondFormGroupValue(value: any) {

        this.isSecondFormGroupValue.emit(value);

    }

}


