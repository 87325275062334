import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { LogEntry } from '../models/LogEntry';
import { GetIpService } from './ipservice'
import { UntypedFormGroup, AbstractControl, ValidationErrors } from '@angular/forms';
import { RegisterService } from 'src/app/register/register.service';
import { Router } from '@angular/router';
import { StatementsService } from 'src/app/layout/statements/statements.service';
import isValidACN from 'is-valid-acn';
import { ValidationFieldNameList } from '../models/validation-field-name-list';
import { Application } from 'src/app/layout/oa/shared/models/oa-application.model';
import { SessionTimerService } from 'session-expiration-alert';
import { OAOrganizationDetails, OAUserDetails } from 'src/app/layout/oa/shared/models/oa-user-details.model';


@Injectable()
export class CommanService {

    static total = 0.3;

    public total: number = 0.3;

    baseURL: string;

    data: any;

    constructor(private httpClient: HttpClient, private statementService: StatementsService, //private sessionTimerService: SessionTimerService,
        @Inject(SESSION_STORAGE) private storage: StorageService, private registerService: RegisterService, private router: Router,
        private validationFieldNameList: ValidationFieldNameList) {
        //this.baseURL = environment.baseApiUrl + 'user/';
        if (sessionStorage.getItem('baseURL'))
            this.baseURL = sessionStorage.getItem('baseURL') + 'user/';
    }

    logEntryAndRefreshToken(page: string, url: string) {
        this.data = this.storage.get('UserToken');
        var log = new LogEntry();
        log.Page = page;
        log.Url = url;
        log.IpAddress = localStorage.getItem("IpAddress");
        log.Browser = window.navigator.appCodeName;
        log.UserId = this.data.userID;
        log.RefreshToken = this.data.refreshToken;
        log.Token = this.data.token;

        var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this.httpClient.post(this.baseURL + 'logentry', log, { headers: reqHeader });
    }

    // updateTokenAndResetTimer() {
    //     var url = window.location.href;
    //     this.data = this.storage.get('UserToken');
    //     this.logEntryAndRefreshToken('OA/investment-detail', url).subscribe(
    //         (data: any) => {
    //             if (data.token) {
    //                 this.data.token = data.token;
    //                 this.data.refreshToken = data.refreshToken;
    //                 this.storage.set('UserToken', this.data);
    //                 this.sessionTimerService.resetTimer();
    //             }
    //         },
    //         (err: any) => {
    //         }
    //     );
    // }

    saveForLater(applicationModel: Application, workflowStep: string) {
        var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        var params = new HttpParams().set('workflowStep', workflowStep);
        return this.httpClient.post(this.baseURL.replace('user', 'OA') + 'SaveForLater', applicationModel, { params: params, headers: reqHeader });
    }

    submitEmailForExternalUser(email: string) {
        var params = new HttpParams().set('email', email);
        params = params.append('applicationId', localStorage.getItem('ApplicationId'));
        return this.httpClient.get(this.baseURL.replace('user', 'OA') + 'SubmitEmailForExternalUser', { params: params });
    }

    getPersonDetailsForRegistration() {
        var params = new HttpParams().set('applicationId', localStorage.getItem('ApplicationId'));
        return this.httpClient.get(this.baseURL.replace('user', 'OA') + 'GetPersonDetailsForRegistration', { params: params });
    }

    getAutralianAddress(value: string) {
        var params = new HttpParams().set('address', value);
        params = params.append('instanceId', this.storage.get("instanceId"));
        return this.httpClient.get(this.baseURL.replace('user', 'OA') + 'FetchAustralianAddress', { params: params });

    }

    getAutralianAddressByID(id: number) {
        var params = new HttpParams().set('id', id.toString());
        params = params.append('instanceId', this.storage.get("instanceId"));
        return this.httpClient.get(this.baseURL.replace('user', 'OA') + 'GetAddressByID', { params: params });
    }

    deleteExistingShareholder() {
        var params = new HttpParams().set('applicationId', localStorage.getItem('ApplicationId'));
        return this.httpClient.get(this.baseURL.replace('user', 'OA') + 'DeleteExistingShareholder', { params: params });
    }

    getHarmonyAddressByID(value: string) {
        var params = new HttpParams().set('address', value);
        params = params.append('instanceId', this.storage.get("instanceId"));
        return this.httpClient.get(this.baseURL.replace('user', 'OA') + 'HarmonyAddress', { params: params });
    }

    refreshToken() {
        this.data = this.storage.get('UserToken');

        var log = new LogEntry();
        log.RefreshToken = this.data.refreshToken;
        log.Token = this.data.token;

        var data = JSON.stringify(log);
        var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this.httpClient.post(this.baseURL + 'logentry', data, { headers: reqHeader });
    }

    // getFormValidationErrors(form: FormGroup) {
    //     const result = [];
    //     Object.keys(form.controls).forEach(key => {

    //         const controlErrors: ValidationErrors = form.get(key).errors;
    //         if (controlErrors) {
    //             Object.keys(controlErrors).forEach(keyError => {
    //                 result.push({
    //                     'control': key,
    //                     'error': keyError,
    //                     'value': controlErrors[keyError]
    //                 });
    //             });
    //         }
    //     });

    //     return result;

    // }

    getEndUserFieldName(FieldName): any {
        let rtndata
        rtndata = this.validationFieldNameList.FieldNameList.filter(
            code => code.FieldName === FieldName);
        return rtndata
    }

    getFormValidationErrors(form: UntypedFormGroup) {
        const result = [];
        var rtnData = [];

        Object.keys(form.controls).forEach(key => {
            const controlErrors: ValidationErrors = form.get(key).errors;
            if (controlErrors) {
                Object.keys(controlErrors).forEach(keyError => {
                    result.push({
                        'control': key,
                        'error': keyError,
                        'value': controlErrors[keyError]
                    });
                });
            }
        });


        for (var i = 0; i < result.length; i++) {
            if (result[i].value == true) {
                var data = this.getEndUserFieldName(result[i].control)
                if (data.length > 0) {
                    var fieldname = data[0].Message

                    rtnData.push({
                        'EndUserFieldName': fieldname
                    });
                }
            }

        }
        return rtnData;

    }

    getAllFormcontrol(form: UntypedFormGroup) {
        const result = [];
        var rtnData = [];

        Object.keys(form.controls).forEach(key => {
            const controlErrors: ValidationErrors = form.get(key).errors;
            result.push({
                'control': key,
                'error': true,
                'value': true
            });
        });


        for (var i = 0; i < result.length; i++) {
            var data = this.getEndUserFieldName(result[i].control)
            if (data.length > 0) {
                var fieldname = data[0].Message

                rtnData.push({
                    'EndUserFieldName': fieldname
                });
            }

        }

        return rtnData;

    }

    getEndUserFieldNameList(FieldName: any): any {
        let rtndata
        let rtndataList = []
        for (var i = 0; i < FieldName.length; i++) {
            rtndata = this.validationFieldNameList.FieldNameList.filter(
                code => code.FieldName === FieldName[i]);

            rtndataList.push({
                'EndUserFieldName': rtndata[0].Message
            });
        }

        return rtndataList
    }

    OnlyNumbers(evt: any) {

        var evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode >= 48 && charCode <= 57) ||
            // (charCode >= 96 && charCode <= 105) ||
            charCode == 9 ||
            charCode == 8) {
            return true;
        }
        return false;
    }

    OnlyNumbersWithDecimals(evt: any) {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        var value = evt.target.value;
        var dotcontains = value.indexOf(".") != -1;
        if (dotcontains)
            if (charCode == 46) return false;
        if (charCode == 46) return true;
        if (charCode > 31 && (charCode < 48 || charCode > 57))
            return false;
        return true;
        // var evt = (evt) ? evt : window.event;
        // var charCode = (evt.which) ? evt.which : evt.keyCode;
        // if ((charCode >= 48 && charCode <= 57) ||
        //     (charCode >= 96 && charCode <= 105) ||
        //     charCode == 9 ||
        //     charCode == 8 ||
        //     charCode == 46) {
        //         debugger;
        //     if (evt.target.valueevt.target.value.split('.').length > 1)
        //         return false;
        //     else
        //         return true;
        // }
        // return false;
    }

    OnlyNumberInvestmentDetails(event: any) {
        var charCode = event.keyCode;
        if ((charCode >= 48 && charCode <= 57) ||
            (charCode >= 96 && charCode <= 105) ||
            charCode == 37 ||
            charCode == 39 ||
            charCode == 46 ||
            charCode == 9 ||
            charCode == 8) {
            return true;
        }
        return false;
    }

    OnlyNumbersWithPlusChar(evt: any) {

        var evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;

        if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    OnlyAlphaNumericsWithSpace(event: any) {
        var charCode = event.keyCode;
        if ((charCode >= 65 && charCode <= 90) ||
            (charCode >= 48 && charCode <= 57) ||
            (charCode >= 96 && charCode <= 122) ||
            charCode == 32 ||
            charCode == 37 ||
            charCode == 39 ||
            charCode == 46 ||
            charCode == 9 ||
            charCode == 8)
            return true;
        return false;
    }

    OnlyAlphaNumeric(event: any) {
        var charCode = event.keyCode;
        if ((charCode >= 65 && charCode <= 90) ||
            (charCode >= 48 && charCode <= 57) ||
            (charCode >= 96 && charCode <= 122) ||
            charCode == 32 ||
            charCode == 37 ||
            charCode == 39 ||
            charCode == 46 ||
            charCode == 9 ||
            charCode == 8)
            return true;
        return false;
    }


    ValidateTFN(controlName: string) {
        return (formGroup: UntypedFormGroup) => {
            var input: any = 0;
            const control = formGroup.controls[controlName];

            if (control.errors && !control.errors.validatetfn)
                return;

            input = control.value == null ? "" : control.value;

            if (CommanService.TFNValidation(input) != '')
                control.setErrors({ validatetfn: true });
            else
                control.setErrors(null);
        }
    }

    ValidateABN(controlName: string) {
        return (formGroup: UntypedFormGroup) => {
            var input: any = 0;
            const control = formGroup.controls[controlName];

            if (control.errors && !control.errors.validateabn)
                return;

            input = control.value == null ? "" : control.value;

            if (CommanService.ABNValidation(input) != '')
                control.setErrors({ validateabn: true });
            else
                control.setErrors(null);
        }
    }

    static TFNValidation(input: any) {
        var result = '';
        if (input.length == 8 || input.length == 9)
            result = this.validTFN(input);

        else if (input.length == 0)
            result = '';
        else
            result = 'Invalid Number!';
        return result;
    }



    static ABNValidation(input: any) {
        var result = '';
        if (input.length == 11)
            result = this.validABN(input);
        else if (input.length == 0)
            result = '';
        else
            result = 'Invalid Number!';
        return result;
    }

    static validABN(abn: any) {
        var sum = 0;
        sum += (abn.substring(0, 1) - 1) * 10;
        sum += abn.substring(1, 2) * 1;
        sum += abn.substring(2, 3) * 3;
        sum += abn.substring(3, 4) * 5;
        sum += abn.substring(4, 5) * 7;
        sum += abn.substring(5, 6) * 9;
        sum += abn.substring(6, 7) * 11;
        sum += abn.substring(7, 8) * 13;
        sum += abn.substring(8, 9) * 15;
        sum += abn.substring(9, 10) * 17;
        sum += abn.substring(10, 11) * 19;

        if (sum % 89 == 0)
            return ('');
        else
            return ('Invalid ABN!');
    }

    static validTFN(tfn: any) {
        var sum = 0;
        if
            (
            tfn == "000000000" ||
            tfn == "333333333" ||
            tfn == "444444441" ||
            tfn == "444444442" ||
            tfn == "555555555" ||
            tfn == "666666666" ||
            tfn == "777777777" ||
            tfn == "888888888" ||
            tfn == "987654321"
        )
            return ('');

        switch (tfn.length) {

            case 8:
                sum += tfn.substring(0, 1) * 10;
                sum += tfn.substring(1, 2) * 7;
                sum += tfn.substring(2, 3) * 8;
                sum += tfn.substring(3, 4) * 4;
                sum += tfn.substring(4, 5) * 6;
                sum += tfn.substring(5, 6) * 3;
                sum += tfn.substring(6, 7) * 5;
                sum += tfn.substring(7, 8) * 1;
                break;

            case 9:
                sum += tfn.substring(0, 1) * 1;
                sum += tfn.substring(1, 2) * 4;
                sum += tfn.substring(2, 3) * 3;
                sum += tfn.substring(3, 4) * 7;
                sum += tfn.substring(4, 5) * 5;
                sum += tfn.substring(5, 6) * 8;
                sum += tfn.substring(6, 7) * 6;
                sum += tfn.substring(7, 8) * 9;
                sum += tfn.substring(8, 9) * 10;
                break;

            default:
                return ('Invalid TFN!');
        }

        if (sum % 11 == 0)
            return ('');
        else
            return ('Invalid TFN!');
    }

    checkToken() {

        var temp = this.storage.get("UserToken");
        if (temp) {
            if (temp.token == "") {
                this.router.navigateByUrl("/login");
            } else {
                return true;
            }
        } else {

            this.router.navigateByUrl("/login");
        }
    }

    CheckEmailExist(controlName: string) {
        return (formGroup: UntypedFormGroup) => {
            var result = '';
            const control = formGroup.controls[controlName];

            if (control.errors && !control.errors.emailexist) {
                return;
            }

            this.registerService.checkEmailExist(control.value, +this.storage.get("instanceId")).subscribe(
                (data: any) => {
                    if (data.success === true)
                        control.setErrors({ emailexist: true });
                    else
                        control.setErrors(null);
                },
                (error: any) => {
                    control.setErrors(null);
                }
            );
        }
    }

    showDocument(documentId: number, documentName: string) {
        this.data = this.storage.get('UserToken');
        let bizEntityId = localStorage.getItem('BizEntityId');
        this.statementService.getFile(Math.pow(documentId * 2, 2).toString(), bizEntityId ? bizEntityId : '0', this.data.userID).then((result: any) => {
            this.showFile(result._body, documentName);
        });
    }

    private showFile(blob: any, filename: string) {
        let checkFileType = filename.split('.').pop();
        var fileType: string = '';
        if (checkFileType == "txt") {
            fileType = "text/plain";
        }
        if (checkFileType == "pdf") {
            fileType = "application/pdf";
        }
        if (checkFileType == "doc") {
            fileType = "application/vnd.ms-word";
        }
        if (checkFileType == "docx") {
            fileType = "application/vnd.ms-word";
        }
        if (checkFileType == "xls") {
            fileType = "application/vnd.ms-excel";
        }
        if (checkFileType == "png") {
            fileType = "image/png";
        }
        if (checkFileType == "jpg") {
            fileType = "image/jpeg";
        }
        if (checkFileType == "jpeg") {
            fileType = "image/jpeg";
        }
        if (checkFileType == "gif") {
            fileType = "image/gif";
        }
        if (checkFileType == "csv") {
            fileType = "text/csv";
        }
        let newBlob = new Blob([blob], { type: fileType });

        ///////////////////////////////////  This code has been commented just after updating from Angular 11 to 13  ////////////////////////////////////
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //     window.navigator.msSaveOrOpenBlob(newBlob);
        //     return;
        // }

        let data = window.URL.createObjectURL(newBlob);
        let link = document.createElement('a');
        link.href = data;
        link.download = filename;
        link.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(data);
        }, 100);
    }

    static ACNValidation(acn: string) {

        //var weights = [8, 7, 6, 5, 4, 3, 2, 1];
        // check length is 9 digits
        if (acn.length == 9) {
            // apply ato check method
            let valid = isValidACN(acn)
            // return acn != check.toString() ? 'Invalid ACN' : '';
            return !valid ? 'Invalid ACN' : '';

        }
        return 'Invalid ACN';
    }
}

export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustmatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustmatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

export function NotMatch(controlName1: string, controlName2: string) {
    return (formGroup: UntypedFormGroup) => {
        const control1 = formGroup.controls[controlName1];
        const control2 = formGroup.controls[controlName2];

        if (control2.errors && !control2.errors.notmatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control1.value === control2.value) {
            control2.setErrors({ notmatch: true });
        } else {
            control2.setErrors(null);
        }
    }
}

export function NoWhitespaceValidator(controlName: string) {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.controls[controlName];
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;

        // set error on Control if validation fails
        if (!isValid) {
            control.setErrors({ whitespace: true });
        }
        else {
            control.setErrors(null);
        }
    }
}


export function ValidateTFNWithAbstractControl(control: AbstractControl): any {
    if (control.value) {
        if (control.value.length >= 8) {
            if (CommanService.TFNValidation(control.value) != '')
                return { validatetfn: true };
        }
    }

    return false;
}

export function ValidateABNACNWithAbstractControl(control: AbstractControl): any {
    if (control.value) {
        if (control.value.length == 11) {
            if (CommanService.ABNValidation(control.value) != '')
                return { validateabn: true };
        }
        else if (control.value.length == 9) {
            if (CommanService.ACNValidation(control.value) != '')
                return { validateabn: true };
        }
    }

    return false;
}
