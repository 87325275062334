
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private _router: Router) { }
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('isLoggedin')) {

            if (next.data.roles == "false") {
                this._router.navigate(['/login']);
                return false;
            }
            if (next.data.votes == "false") {
                this._router.navigate(['/login']);
                return false;
            }
            if (next.data.extrnlUser == "true") {
                if (localStorage.getItem('isExternalUser') == "true") {
                    let url = window.location.href;
                    if (url.includes('home'))
                        this._router.navigate(['/get-funds']);
                }

            }
            return true;
        }



        this._router.navigate(['/login']);
        return false;
    }
}
