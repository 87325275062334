export class UserModel {
    Username: string = '';
    InstanceId: number = 0;
    EmailAddress: string = '';
    Password: string = '';
    PasswordQuestionId: string = '';
    PasswordAnswer: string = '';
    PasswordQuestion2Id: string = '';
    PasswordAnswer2: number = 0;
    EmailAsUsername: string = '';
    FirstName: string = '';
    MiddleName: string = '';
    LastName: string = '';
    PhoneNumber: string = '';
    IsExternalUser: Boolean = false;
    ApplicationID: number = 0;
}
