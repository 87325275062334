import { StorageService, SESSION_STORAGE } from 'ngx-webstorage-service';
import { Injectable, Injector, Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError, empty } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { GetIpService } from './shared/services/ipservice';



@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
    currentValue: any;
    loginUrl: string = '';
    baseURL: string;
    requestArray: Array<string> = [
        'style', 'authenticate', 'authenticate/listed', 'authenticate/sso', 'register', 'forgot/username', 'forgot/password',
        'forgot/password/reset', 'tandcandquestions', 'activate', 'GetCountries', 'GetProducts', 'CheckEmailExist'
    ];
    constructor(@Inject(SESSION_STORAGE) private storage: StorageService, private router: Router, private dialogRef: MatDialog, private getIpService: GetIpService, private httpClient: HttpClient) {
        //this.baseURL = environment.baseApiUrl;
    }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

        var authReq: any;

        this.baseURL = sessionStorage.getItem('baseURL');
        if (this.requestArray.includes(req.url.replace(this.baseURL + 'user/', ''))
            || this.requestArray.includes(req.url.replace(this.baseURL + 'common/', ''))
            || this.requestArray.includes(req.url.replace(this.baseURL + 'portal/', ''))
            || this.requestArray.includes(req.url.replace(this.baseURL + 'product/', ''))
            || req.url.includes('json')
        ) {
            authReq = req.clone();
        }
        else {
            this.currentValue = this.storage.get('UserToken');
            if (!this.currentValue) {
                this.storage.remove('UserToken');
                // this.router.navigate(['./login']);
            }
            else {
                //this.currentValue = 'bearer ' + this.storage.get('UserToken');
                this.currentValue = 'bearer ' + this.storage.get('UserToken').token;

                authReq = req.clone({ headers: req.headers.set('Authorization', this.currentValue) });
            }
        }
        if (authReq == undefined)
            return empty();

        return next.handle(authReq)
            .pipe(catchError((error, caught) => {

                if (error.status == 401) {
                    var route = '';
                    if (localStorage.getItem('ListedInvestorLimited'))
                        route = 'login/sha';
                    else
                        route = 'login';
                    localStorage.clear();
                    this.dialogRef.closeAll();
                    this.storage.remove('UserToken');
                    this.router.navigate([route]);
                }
                return throwError(error);
            })) as any;


    }

    // errorHandler(error: HttpErrorResponse) {
    //     var status = error.status.toString();
    //     switch (status) {
    //         case "400":
    //             alert("Bad Request");
    //             break;
    //         case "401":
    //             alert("Unauthorized");
    //             break;
    //         case "404":
    //             alert("Not Found");
    //             break;
    //         case "500":
    //             alert("Internal Server Error");
    //             break;
    //         default:
    //             alert("Something went wrong");
    //     }
    // }


}
