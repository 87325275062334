/**
 * This module is used to language translations.
 * The translations are saved in a json file in /src/app/assets/i18n directory
 * Docs: https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-angular7-app-with-ngx-translate
 */
import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import ngx-translate and the http loader
import {
    TranslateLoader,
    TranslateModule,
    TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PasswordMaintenanceComponent } from '../../password-maintenance/password-maintenance.component';
import { UserDetailsComponent } from '../../user-details/user-details.component';
import { MaterialModule } from '../../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { NgxCaptchaModule } from 'ngx-captcha';

// ngx-translate - required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({

    imports: [
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        MatPasswordStrengthModule.forRoot(),
        NgxCaptchaModule
    ],
    declarations: [PasswordMaintenanceComponent, UserDetailsComponent],
    exports: [
        TranslateModule, PasswordMaintenanceComponent, UserDetailsComponent
    ],
})
export class LanguageTranslationModule {
    constructor(
        private translate: TranslateService,
    ) {
        // Gets Default language from browser if available, otherwise set English ad default
        this.translate.addLangs(['en', 'fr', 'ur', 'es', 'it', 'fa', 'de', 'zh-CHS']);
        this.translate.setDefaultLang('en');
        const browserLang = this.translate.getBrowserLang();
        this.translate.use(browserLang.match(/en|fr|ur|es|it|fa|de|zh-CHS/) ? browserLang : 'en');
    }
}
