export class UpdateDetails {
    InstanceID: number = 0;
    BizEntityID: number = 0;
    UserID: number = 0;
    AccountID: number = 0;
    SelectedCommPref: number = 0;
    CanCopyToAdvisor: boolean = false;
    DrpPercentage: number = 0;
    DrpHolding: number = 0;
    Question1: number = 0;
    Question2: number = 0;
    SelectedDrp: number = 0;
    CurrentPassword: string = '';
    NewPassword: string = '';
    Answer1: string = '';
    Answer2: string = '';
    Email: string = '';
    Bsb: string = '';
    AccountNumber: string = '';
    AccountName: string = '';
    BankName: string = '';
    SwiftCode: string = '';
    Iban: string = '';
    BranchAddress: string = '';
    Message: string = '';
    TfnAbn: TfnAbnModel = new TfnAbnModel();
    Phones: PhoneModel[] = [];
    TaxationDetailsDataModels: TaxationDetailsModel[] = [];
    AddressDataModel: AddressModel = new AddressModel();
    Endpoint: string = '';
    CommunicationId: number = 0;
}

export class TfnAbnModel {
    Party1TaxResidenceCountryId: number = 0;
    Party2TaxResidenceCountryId: number = 0;
    Party3TaxResidenceCountryId: number = 0;
    Party1DateOfBirth: Date = new Date();
    Party2DateOfBirth: Date = new Date();
    Party3DateOfBirth: Date = new Date();
    Party1GIINTIN: string = '';
    Party2GIINTIN: string = '';
    Party3GIINTIN: string = '';
    Party1CRSFATCAParticipationId: number = 0;
    Party2CRSFATCAParticipationId: number = 0;
    Party3CRSFATCAParticipationId: number = 0;
    TaxResidenceAndDoBVisible: boolean = false;
    Party1TFNABN: string = '';
    Party2TFNABN: string = '';
    Party3TFNABN: string = '';
    Party1TaxOfficeTypeId: number = 0;
    Party2TaxOfficeTypeId: number = 0;
    Party3TaxOfficeTypeId: number = 0;
}

export class PhoneModel {
    CommunicationID: number = 0;
    CommunicationTypeID: number = 0;
    Name: string = '';
    CommunicationTypeName: string = '';

}

export class AddressModel {
    TotalStates: any;
    ChessCountries: any;
    SelectedCountry: string = '';
    Suburb: string = '';
    SelectedState: string = '';
    Postcode: string = '';
    AddressLine1: string = '';
    AddressLine2: string = '';
    AddressLine3: string = '';
    AddressLine4: string = '';
    AddressLine5: string = '';
}

export class TaxationDetailsModel {
    BizEntityPartyId: number = 0;
    Type: string = '';
    Name: string = '';
    TaxationFileNumber: string = '';
    IsExemption: boolean = false;
    ABN: string = '';
}
