import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UpdateDetails } from '../models/UpdateDetailsModel';
import { UpdateDetailsService } from 'src/app/layout/update-details/update-details.service';
import { SnackBarService } from '../services/snackbar.service';

@Component({
    selector: 'custom-toastr',
    templateUrl: './toastr.component.html',
    //styleUrls: ['./toastr.component.scss'],
    styles: [`
      :host {
        background: transparent;
        background-color: #ffffff00;
        background-image: none;
        box-shadow: none !important;
        content: none;
        position: relative;
        overflow: hidden;
        margin: 0 0 6px;
        padding: 10px 10px 10px 10px;
        width: 400px !Important;
        border-radius: 3px 3px 3px 3px;
        color: #FFFFFF;
        pointer-events: all;
        cursor: pointer;
      }
      .btn-pink {
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0);
      }
    `],
    animations: [
        trigger('flyInOut', [
            state('inactive', style({
                opacity: 0,
            })),
            transition('inactive => active', animate('400ms ease-out', keyframes([
                style({
                    transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
                    opacity: 0,
                }),
                style({
                    transform: 'skewX(20deg)',
                    opacity: 1,
                }),
                style({
                    transform: 'skewX(-5deg)',
                    opacity: 1,
                }),
                style({
                    transform: 'none',
                    opacity: 1,
                }),
            ]))),
            transition('active => removed', animate('400ms ease-out', keyframes([
                style({
                    opacity: 1,
                }),
                style({
                    transform: 'translate3d(100%, 0, 0) skewX(30deg)',
                    opacity: 0,
                }),
            ]))),
        ]),
    ],
    preserveWhitespaces: true,
})

export class CustomToastr extends Toast {
    // used for demo purposes

    // constructor is only necessary when not using AoT
    constructor(protected toastrService: ToastrService, private router: Router,
        public toastPackage: ToastPackage, private updateService: UpdateDetailsService, private snackbar: SnackBarService
    ) {
        super(toastrService, toastPackage);
    }

    redirectToUpdateDetails(value: string) {
        if (value == '3') // for status on overview page for CRS/FATCA
        {
            localStorage.setItem("OpenCRS", "true");
            this.router.navigate(['./update-details']);
            this.remove();
        }
        if (value == '4') { // for email comm pref on update details page
            localStorage.setItem("OpenECP", "true");
            this.router.navigate(['./update-details']);
            this.remove();
        }
        if (value == '5') { // for email on update details page
            let model = new UpdateDetails();
            let email = localStorage.getItem("Email");
            model.Email = email ? email : '';
            model.SelectedCommPref = 3;
            model.Endpoint = "SaveEmailCommPref";
            this.updateService.saveDetails(model).subscribe(
                (data: any) => {
                    this.snackbar.openSnackBar(data.success, 1);
                },
                (error: any) => {
                    this.snackbar.openSnackBar(error.error, 2);
                }
            );
            this.remove();
            this.router.navigateByUrl('/home', { skipLocationChange: true }).then(() =>
                this.router.navigate(['holder-overview']));
        }
    }

    // action(event: Event) {
    //     event.stopPropagation();
    //     this.toastPackage.triggerAction();
    //     return false;
    // }
}
