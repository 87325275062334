import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';


@Injectable()
export class GetIpService {

    private httpClient: HttpClient;

    constructor(handler: HttpBackend) {
        this.httpClient = new HttpClient(handler);

    }

    getIPAddress() {
        let baseApiUrl;
        baseApiUrl = localStorage.getItem('baseURL');
        return this.httpClient.get(baseApiUrl);
    }

}
