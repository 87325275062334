import { Injectable, EventEmitter, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Response, ResponseContentType } from '@angular/http';
import { Http, URLSearchParams, Headers, RequestOptions } from '@angular/http';
import { Observable, pipe, of, Subject } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

import { SnackBarService } from 'src/app/shared/services/snackbar.service';

@Injectable()
export class StatementsService {

    baseURL: string;
    // reqHeader: any = new HttpHeaders({ 'x-api-version': '3.0.Admin' });

    constructor(private _http: Http, private _httpClient: HttpClient, @Inject(SESSION_STORAGE) private storage: StorageService, private snackbar: SnackBarService) {
        //  this.baseURL = environment.baseApiUrl + 'portal/';
        if (sessionStorage.getItem('baseURL'))
            this.baseURL = sessionStorage.getItem('baseURL') + 'portal/';
    }

    public getFile(documentId: string, bizEntityId: string, userId: string) {
        return this.getFileEndpoint(documentId, bizEntityId, userId).toPromise();
    }

    public getFileEndpoint(documentId: string, bizEntityId: string, userId: string): Observable<any> {
        var params = new URLSearchParams();
        params.append('documentId', documentId);
        params.append('bizEntityId', bizEntityId);
        params.append('userId', userId);
        var reqHeader = new Headers({ 'Authorization': 'bearer ' + this.storage.get('UserToken').token });
        var reqs = new RequestOptions({ headers: reqHeader, params: params, responseType: ResponseContentType.Blob });
        return this._http.get(this.baseURL + 'DownloadFile', reqs)
            .pipe(
                map((response: Response) => {
                    return response;
                }),
                catchError((error, caught) => {
                    if (error.status = 404)
                        this.snackbar.openSnackBar("File not found", 2);
                    return error;
                }));
    }

    getStatements(bizEntityId: string, fromDate: string, toDate: string) {
        var params = new HttpParams().set('bizEntityId', bizEntityId);
        params = params.set('fromDate', fromDate);
        params = params.set('toDate', toDate);
        return this._httpClient.get(this.baseURL + 'GetStatements', { params: params });
    }

    getDocuments(bizEntityId: string) {
        var params = new HttpParams().set('bizEntityId', bizEntityId);
        return this._httpClient.get(this.baseURL + 'GetDocuments', { params: params });
    }
}
