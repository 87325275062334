import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';


@Injectable()
export class CompanyWorkFlowService {

    baseURL: string;
    userData: any;

    constructor(private _httpClient: HttpClient, @Inject(SESSION_STORAGE) private storage: StorageService) {
        //this.baseURL = environment.baseApiUrl + 'oa/';
        if (sessionStorage.getItem('baseURL'))
            this.baseURL = sessionStorage.getItem('baseURL') + 'oa/';
        this.userData = this.storage.get('UserToken');
    }

    getRegulatoryInfo() {
        let applicationId = localStorage.getItem("ApplicationId");
        var params = new HttpParams().set('applicationId', applicationId ? applicationId : '');
        return this._httpClient.get(this.baseURL + 'GetRegulatoryInfo', { params: params });
    }

    submitCompanyDetails(organisationmodel: any) {
        var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this._httpClient.post(this.baseURL + 'SaveCompanyDetails', organisationmodel, { headers: reqHeader });
    }

    getCompanyDetails(appOrgID: string) {
        let applicationId = localStorage.getItem("ApplicationId");
        var params = new HttpParams().set('applicationId', applicationId ? applicationId : '');
        params = params.set('AppOrgID', appOrgID);
        return this._httpClient.get(this.baseURL + 'GetCompanyDetails', { params: params });
    }

    submitRegulatorDetails(organisationmodel: any) {
        var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this._httpClient.post(this.baseURL + 'SaveRegulatoryInformation', organisationmodel, { headers: reqHeader });
    }

    getComanyNameByABN(abn: string) {
        var params = new HttpParams().set('abn', abn);
        return this._httpClient.get(this.baseURL + 'SearchByABN', { params: params });
    }

    getComanyNameByACN(acn: string) {
        var params = new HttpParams().set('acn', acn);
        return this._httpClient.get(this.baseURL + 'SearchByACN', { params: params });
    }

    getAllCompanyName(name: string) {
        var params = new HttpParams().set('name', name);
        return this._httpClient.get(this.baseURL + 'SearchByName', { params: params });
    }

    submitTaxationDetails(organisationmodel: any) {
        var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this._httpClient.post(this.baseURL + 'SaveTaxationInfo', organisationmodel, { headers: reqHeader });
    }

    getTaxationInformation() {
        let applicationId = localStorage.getItem("ApplicationId");
        var params = new HttpParams().set('applicationId', applicationId ? applicationId : '');
        return this._httpClient.get(this.baseURL + 'GetTaxationInformation', { params: params });
    }

    getUploadDocumentData() {
        let applicationId = localStorage.getItem("ApplicationId");
        var params = new HttpParams().set('applicationId', applicationId ? applicationId : '');
        return this._httpClient.get(this.baseURL + 'GetUploadDocumentData', { params: params });
    }

    getSignatories(relationshipTypeId: any) {
        let applicationId = localStorage.getItem("ApplicationId");
        var params = new HttpParams().set('applicationId', applicationId ? applicationId : '');
        params = params.set('relationshipTypeId', relationshipTypeId);
        return this._httpClient.get(this.baseURL + 'GetPersonCount ', { params: params });
    }

    getShareholder(relationshipTypeId: any) {

        let applicationId = localStorage.getItem("ApplicationId");
        var params = new HttpParams().set('applicationId', applicationId ? applicationId : '');
        params = params.set('relationshipTypeId', relationshipTypeId);
        return this._httpClient.get(this.baseURL + 'GetPersonCount', { params: params });
    }

    getShareholderCount(relationshipTypeId: any) {
        let applicationId = localStorage.getItem("ApplicationId");
        var params = new HttpParams().set('applicationId', applicationId ? applicationId : '');
        params = params.set('relationshipTypeId', relationshipTypeId);
        return this._httpClient.get(this.baseURL + 'GetShareholderCount', { params: params });
    }


    getApplicationSummary() {
        let applicationId = localStorage.getItem("ApplicationId");
        var params = new HttpParams().set('applicationId', applicationId ? applicationId : '');
        return this._httpClient.get(this.baseURL.replace('oa', 'ProofOfIdentity') + 'GetApplicationSummary', { params: params });
    }

    getApplicationReviewData() {

        let applicationId = localStorage.getItem("ApplicationId");
        var params = new HttpParams().set('applicationId', applicationId ? applicationId : '');
        return this._httpClient.get(this.baseURL.replace('oa', 'ProofOfIdentity') + 'GetApplicationReviewData', { params: params });
    }

    postsubmitApplication(workflowStep: any, sendEmailByAdmin: any) {
        let applicationId = localStorage.getItem("ApplicationId");
        var params = new HttpParams().set('applicationId', applicationId ? applicationId : '');
        params = params.append('workflowStep', workflowStep);
        if (localStorage.getItem('isExternalUser') == 'true' && localStorage.getItem('DirectUserUserID'))
            params = params.append('userId', localStorage.getItem('DirectUserUserID'));
        else
            params = params.append('userId', this.userData.userID);
        params = params.append('sendEmailByAdmin', sendEmailByAdmin);
        return this._httpClient.post(this.baseURL + 'SubmitApplication', null, { params: params });
    }

    getsubmissionSummary() {
        let applicationId = localStorage.getItem("ApplicationId");
        var params = new HttpParams().set('applicationId', applicationId ? applicationId : '');
        params = params.append('userId', this.userData.userID);
        return this._httpClient.get(this.baseURL + 'GetApplicationSummary', { params: params });
    }

    saveSignatoryInfo(signatory: any, workflowStep: any) {
        let applicationId = localStorage.getItem("ApplicationId");
        var params = new HttpParams().set('applicationId', applicationId ? applicationId : '');
        params = params.append('signatory', signatory);
        params = params.append('workflowStep', workflowStep);
        params = params.append('userId', this.userData.userID);
        return this._httpClient.post(this.baseURL + 'SaveSignatoryInfo', null, { params: params });
    }

    getSignatoryInfo(relationshipId: any) {
        let applicationId = localStorage.getItem("ApplicationId");
        var params = new HttpParams().set('applicationId', applicationId ? applicationId : '');
        params = params.append('relationshipId', relationshipId);
        return this._httpClient.get(this.baseURL + 'GetSignatoryInfo', { params: params });
    }

    saveInvestorEligibility(eligibility: any, scsihasOtherDetails: any, workflowStep: any) {
        let applicationId = localStorage.getItem("ApplicationId");
        var params = new HttpParams().set('applicationId', applicationId ? applicationId : '');
        params = params.append('eligibility', eligibility);
        params = params.append('scsihasOtherDetails', scsihasOtherDetails);
        params = params.append('workflowStep', workflowStep);
        params = params.append('userId', this.userData.userID);
        return this._httpClient.post(this.baseURL + 'SaveInvestorEligibility', null, { params: params });
    }

    getInvestorEligibility() {
        let applicationId = localStorage.getItem("ApplicationId");
        var params = new HttpParams().set('applicationId', applicationId ? applicationId : '');
        return this._httpClient.get(this.baseURL + 'GetInvestorEligibility', { params: params });
    }

    deleteExistingApplication(applicationId: string) {
        var params = new HttpParams().set('applicationId', applicationId);
        params = params.append('userId', this.userData.userID);
        return this._httpClient.get(this.baseURL + 'CancelApplication', { params: params });
    }


    updateWorkflowStep(workflowStep: any) {
        let applicationId = localStorage.getItem("ApplicationId");
        var params = new HttpParams().set('applicationId', applicationId ? applicationId : '');
        params = params.append('workflowStep', workflowStep);
        params = params.append('userId', this.userData.userID);
        return this._httpClient.post(this.baseURL + 'UpdateWorkflowStep', null, { params: params });
    }


    getAllPersonsForOnlineVerification() {
        let applicationId = localStorage.getItem("ApplicationId");
        var params = new HttpParams().set('applicationId', applicationId ? applicationId : '');
        return this._httpClient.get(this.baseURL + 'GetAllPersonsForOnlineVerification', { params: params });
    }

    // getGreenIDEnvironment() {
    //     let applicationId = localStorage.getItem("ApplicationId");
    //     var params = new HttpParams().set('applicationId', applicationId ? applicationId : '');
    //     return this._httpClient.get(this.baseURL + 'GetGreenIDEnvironment', { params: params });
    // }

    getTokenGreenID(applicationPersonId: string) {
        // var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        let applicationId = localStorage.getItem("ApplicationId");
        let params = new HttpParams().set('applicationId', applicationId ? applicationId : '');
        params = params.append('applicationPersonId', applicationPersonId);
        params = params.append('instanceId', this.storage.get("instanceId"));
        return this._httpClient.post(this.baseURL + 'RegisterVerification', null, { params: params });

    }

    getUpdatePersonDetails(PersonDetailsUpdateDataModel: any) {
        var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this._httpClient.post(this.baseURL + 'UpdatePersonDetails', PersonDetailsUpdateDataModel, { headers: reqHeader });

    }

    updateApplicationPersonVerificationStatus(applicationPersonId: string, status: string) {
        var params = new HttpParams().set('applicationPersonId', applicationPersonId);

        params = params.append('status', status);
        return this._httpClient.post(this.baseURL + 'UpdateApplicationPersonVerificationStatus', null, { params: params });
    }

    deleteExistingDocument(documentID: any) {
        let applicationId = localStorage.getItem("ApplicationId");
        var params = new HttpParams().set('applicationId', applicationId);
        params = params.append('documentId', documentID);
        return this._httpClient.post(this.baseURL + 'DeleteAlreadyUploadedDocument', null, { params: params });
    }
}
