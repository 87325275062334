import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthHttpInterceptor } from './authinterceptor';

import { StorageServiceModule } from 'ngx-webstorage-service';
import { MaterialModule } from './shared/material.module';
import { FormsModule } from '@angular/forms';
import { MatPasswordStrengthComponent } from './mat-password-strength/mat-password-strength.component';
import { MatPasswordStrengthInfoComponent } from './mat-password-strength-info/mat-password-strength-info.component';
import { LoginService } from './login/login.service';
import { SnackbarComponent } from './shared/snackbar/snackbar.component';
import { LayoutModule } from '@angular/cdk/layout';
import { CommanService } from './shared/services/commanservice';
import { DialogComponent, ServiceForDialog } from './shared/dialog/dialog.component';
import { GetIpService } from 'src/app/shared/services/ipservice';
import { SnackBarService } from './shared/services/snackbar.service';
import { RegisterService } from './register/register.service';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { CustomToastr } from './shared/toastr/toastr.component';
import { UpdateDetailsService } from './layout/update-details/update-details.service';
import { StatementsService } from './layout/statements/statements.service';
import { HttpModule } from '@angular/http';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
//import { GlobalErrorService } from './global-error.service';
import { DialogModule } from './shared/dialog/dialog.module';
import { AuthGuard } from './shared/guard';
import { ReplacePipe } from './shared/pipes/replace.pipe';


//import { AuthModule } from 'angular-auth-oidc-client';
// const oidc_configuration = 'assets/auth.clientConfiguration.json';
// export function loadConfig(oidcConfigService: OidcConfigService) {
//     return () => oidcConfigService.load(oidc_configuration);
// }

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpModule,
        MaterialModule,
        FormsModule,
        LanguageTranslationModule,
        AppRoutingModule,
        StorageServiceModule,
        LayoutModule,
        DialogModule,
        ToastrModule.forRoot({
            // timeOut: 0,
            toastComponent: CustomToastr,
            preventDuplicates: true
        }),
        ToastContainerModule,
        BackButtonDisableModule.forRoot()
        //AuthModule.forRoot()
    ],
    // exports: [AutocompleteComponent],
    declarations: [AppComponent, MatPasswordStrengthComponent, MatPasswordStrengthInfoComponent, CustomToastr, SnackbarComponent, DialogComponent, ReplacePipe],
    providers: [{
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        }, AuthGuard, LoginService, CommanService, StatementsService, GetIpService, ServiceForDialog, SnackBarService, RegisterService, UpdateDetailsService,
        { provide: ErrorHandler }],
    bootstrap: [AppComponent]
})



export class AppModule {
    // constructor(public oidcSecurityService: OidcSecurityService) {
    //     //     // const config: OpenIdConfiguration = {
    //     //     //     stsServer: "http://localhost:5000",
    //     //     //     redirect_url: 'http://localhost:4200/',
    //     //     //     // The Client MUST validate that the aud (audience) Claim contains its client_id value registered at the Issuer identified by the iss (issuer) Claim as an audience.
    //     //     //     // The ID Token MUST be rejected if the ID Token does not list the Client as a valid audience, or if it contains additional audiences not trusted by the Client.
    //     //     //     client_id: 'spa',
    //     //     //     response_type: 'code', // 'id_token token' Implicit Flow
    //     //     //     scope: ' openid profile api1',
    //     //     //     post_logout_redirect_uri:'http://localhost:4200/',


    //     //     //     // id_token C8: The iat Claim can be used to reject tokens that were issued too far away from the current time,
    //     //     //     // limiting the amount of time that nonces need to be stored to prevent attacks.The acceptable range is Client specific.

    //     //     // };
    //     const config: OpenIdConfiguration = {
    //         stsServer: "http://localhost:44312",
    //         redirect_url: 'http://localhost:4200/',
    //         client_id: 'angular_spa',
    //         response_type: 'id_token token',
    //         scope: ' openid profile api1',
    //         post_logout_redirect_uri: 'http://localhost:4200/',
    //         forbidden_route: '/forbidden',
    //         unauthorized_route: '/unauthorized',
    //         silent_renew: true,
    //         silent_renew_url: 'http://localhost:4200' + '/silent-renew.html',
    //         history_cleanup_off: true,
    //         auto_userinfo: true,
    //         log_console_warning_active: true,
    //         log_console_debug_active: true,
    //         max_id_token_iat_offset_allowed_in_seconds: 10,
    //     };

    //     const authWellKnownEndpoints: AuthWellKnownEndpoints = {
    //         issuer: "http://localhost:44312/",
    //         authorization_endpoint: "http://localhost:44312/connect/authorize",
    //         token_endpoint: "http://localhost:44312/connect/token",
    //         userinfo_endpoint: "http://localhost:44312/connect/userinfo",
    //         end_session_endpoint: "http://localhost:44312/connect/endsession",
    //         check_session_iframe: "http://localhost:44312/connect/checksession",
    //         revocation_endpoint: "http://localhost:44312/connect/revocation",
    //         introspection_endpoint: "http://localhost:44312/connect/introspect",
    //     };

    //     this.oidcSecurityService.setupModule(config, authWellKnownEndpoints);
    // }
}
