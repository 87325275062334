import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/shared/snackbar/snackbar.component';


@Injectable()
export class SnackBarService {

  constructor(private _snackBar: MatSnackBar) { }

  openSnackBar(message: string, type: number) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: 5 * 1000,
      data: { message: message, type: type }
    });
  }

  openSnackBarForSpecifiedTime(message: string, type: number, duration: number) {
    this._snackBar.openFromComponent(SnackbarComponent, {
      duration: duration * 1000,
      data: { message: message, type: type }
    });
  }
}