import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UserModel } from '../shared/models/UserModel';




@Injectable()
export class RegisterService {

    baseURL: string;

    constructor(private _httpClient: HttpClient) {
        // this.baseURL = environment.baseApiUrl + 'user/';
        if (sessionStorage.getItem('baseURL'))
            this.baseURL = sessionStorage.getItem('baseURL') + 'user/';
    }

    public registerUser = (model: UserModel) => {
        var data = JSON.stringify({
            "InstanceId": model.InstanceId,
            "Emailaddress": model.EmailAddress,
            "Password": model.Password,
            "PasswordQuestionId": model.PasswordQuestionId,
            "PasswordAnswer": model.PasswordAnswer,
            "PasswordQuestion2Id": model.PasswordQuestion2Id,
            "PasswordAnswer2": model.PasswordAnswer2,
            "EmailAsUsername": model.EmailAsUsername,
            "FirstName": model.FirstName,
            "MiddleName": model.MiddleName,
            "LastName": model.LastName,
            "PhoneNumber": model.PhoneNumber,
            "IsExternalUser": model.IsExternalUser,
            "ApplicationID": model.ApplicationID
        });
        var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this._httpClient.post(this.baseURL + 'register', data, { headers: reqHeader });
    }

    getTermsAndConditions(instanceId: string) {
        var params = new HttpParams().set('instanceId', instanceId);
        return this._httpClient.get(this.baseURL + 'tandcandquestions', { params: params });
    }

    checkEmailExist(email: string, instanceId: number) {
        var params = new HttpParams().set('email', email).set('instanceId', instanceId);
        return this._httpClient.get(this.baseURL + 'CheckEmailExist', { params: params });
    }
}
