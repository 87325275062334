import { Component, Inject } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
    selector: 'snackbar',
    templateUrl: 'snackbar.component.html',
    styleUrls: ['snackbar.component.scss'],
})
export class SnackbarComponent {

    constructor(
        private _snackBar: MatSnackBar, private router: Router,
        @Inject(MAT_SNACK_BAR_DATA) public data: any) {
    }

    dismissSnackbar() { this._snackBar.dismiss(); }

    // redirectToUpdateDetails(value: number) {
    //     if (value == 3) // for status on overview page for CRS/FATCA
    //     {
    //         localStorage.setItem("OpenCRS", "true");
    //         this.router.navigate(['./update-details']);
    //         this._snackBar.dismiss();
    //     }
    //     if (value == 4) { //for email pref on update details page
    //         localStorage.setItem("OpenECP", "true");
    //         this.router.navigate(['./update-details']);
    //         this._snackBar.dismiss();
    //     }
    // }
}
