import { Injectable, Inject, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
//import { environment } from '../../environments/environment';
import { UserModel } from '../shared/models/UserModel';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';


@Injectable()
export class LoginService {

    baseURL: string = '';
    constructor(private _httpClient: HttpClient,
        @Inject(SESSION_STORAGE) private storage: StorageService) {
        this.baseURL = sessionStorage.getItem('baseURL') + 'user/';
    }

    getStylesheet(instance: string) {
        this.baseURL = sessionStorage.getItem('baseURL') + 'user/';
        var params = new HttpParams().set('instanceUrl', instance);
        return this._httpClient.get(this.baseURL + 'style', { params: params });
    }

    authenticateUser(userName: string, password: string, isSSO: string) {
        var data = {
            "username": userName,
            "password": password,
            "isSSO": JSON.parse(isSSO),
            "instanceId": this.storage.get('instanceId'),
        };

        var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this._httpClient.post(this.baseURL + 'authenticate', data, { headers: reqHeader });
    }

    authenticateListedUser(productId: string, securityCode: string, hinsrn: string, postcode: string, countryId: string) {
        var data = JSON.stringify({
            "productId": productId,
            "securityCode": securityCode,
            "hinsrn": hinsrn,
            "postcode": postcode,
            "countryId": countryId == "" ? 0 : countryId,
            "instanceId": this.storage.get('instanceId')
        });

        var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this._httpClient.post(this.baseURL + 'authenticate/listed', data, { headers: reqHeader });
    }

    authenticateSSOUser(adminUsername: string, adminPassword: string, userName: string, password: string, isSSO: string) {
        var data = JSON.stringify({
            "username": userName,
            "password": password,
            "isSSO": isSSO,
            "instanceId": this.storage.get('instanceId')
        });
        var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this._httpClient.post(this.baseURL + 'authenticate/sso', data, { headers: reqHeader });
    }

    activateUser(id: string) {
        var params = new HttpParams().set('instanceId', this.storage.get("instanceId"));
        params = params.set("id", id);
        return this._httpClient.get(this.baseURL + 'activate', { params: params });
    }

    changePassword() {
        var params = new HttpParams().set('userId', this.storage.get("UserToken").userID);
        params = params.set("userStatusId", this.storage.get("UserToken").userStatusID);
        params = params.set("instanceId", this.storage.get("instanceId"));
        //params = params.set("impersonation", impersonation);
        return this._httpClient.get(this.baseURL.replace('user', 'portal') + 'ChangePassword', { params: params });
    }

    getCountries() {
        return this._httpClient.get(this.baseURL.replace('user', 'common') + 'GetCountries');
    }

    getProducts(instanceId: string) {
        var params = new HttpParams().set('instanceId', instanceId);
        return this._httpClient.get(this.baseURL.replace('user', 'product') + 'GetProducts', { params: params });
    }

    test() {
        var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this._httpClient.get(this.baseURL + 'test', { headers: reqHeader });
    }

    forgotUsername(email: string) {
        var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        var params = new HttpParams().set('instanceId', this.storage.get("instanceId"));
        params = params.set('email', email);
        return this._httpClient.get(this.baseURL + 'forgot/username', { headers: reqHeader, params: params })
    }

    forgotPassword(username: string) {
        var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        var params = new HttpParams().set('instanceId', this.storage.get('instanceId'));
        params = params.set('username', username);
        return this._httpClient.get(this.baseURL + 'forgot/password', { headers: reqHeader, params: params });
    }

    resetPassword(model: UserModel) {
        var data = JSON.stringify({
            "Username": model.Username,
            "Emailaddress": model.EmailAddress,
            "PasswordAnswer": model.PasswordAnswer,
            "PasswordQuestionId": model.PasswordQuestionId,
            "InstanceId": this.storage.get('instanceId')
        });
        var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this._httpClient.post(this.baseURL + 'forgot/password/reset', data, { headers: reqHeader });
    }
}
