import { Component, Inject, Injectable } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router'
import { FundModel, ExistingApplicationModel, ABNModel } from './dialog.model';
import { CompanyWorkFlowService } from 'src/app/layout/oa/company-workflow/company-workflow.service';
import { SnackBarService } from '../services/snackbar.service';
import { CommanService } from '../services/commanservice';


// export class MyErrorStateMatcher implements ErrorStateMatcher {
//   isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
//     const isSubmitted = form && form.submitted;
//     return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
//   }
// }

@Injectable()
export class ServiceForDialog {
    dialogOnYes: number = 0;
    constructor() { }
}
export interface PeriodicElement {
    applicationId: number;
    started: string;
    investingEntity: string;
    amount: string;
    percentage: string;
}



@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
    providers: [CompanyWorkFlowService]
})


export class DialogComponent {

    returnvalue: any;
    dialogId: number = 0;
    datasource: any;
    displayedColumns: string[] = ['ProductName', 'CustomerCategory', 'MinAmount', 'Option'];
    tandc: string = '';
    privacyPolicy: string = '';
    isDataAvailable: boolean = false;
    showSpinner: boolean = false;
    appSummary: any;
    indiOrJoint: boolean = false;
    company: boolean = false;
    applicationId: string = '0';

    isMailingAddreess: number = 0;
    isResAddreess: number = 0;
    directorList: any;

    memberlist: any;
    trusteelist: any;
    partner: any;
    shareholderlist: any;
    persondetails: any;

    selfManagedPerson: boolean = false;
    WorkFlowStep: any;
    numberOfPartnersValue: any;
    controllerOrBeneficial: any;
    personTrustee: any;
    getAddress: any;
    text: string = '';
    isShowTextNotReadOnly: boolean = true;
    isShow: boolean = false;
    isShowDialog13: boolean = false;

    errorText: string = '';

    displayColumns: string[] = ['Started', 'InvestingEntity', 'Amount', 'Percentage', 'Option'];

    addressType: string[] = ['Residential Address', 'Mailing Address'];

    wholesaleArr: any = [{ id: 2, value: "I will upload an accountant's certificate or other proof" }, { id: 1, value: "I will fill in the sophisticated investor form" }, { id: 3, value: "I will contact the Investment Manager to discuss eligibility as a sophisticated investor" }];

    eligibility: any = [
        //{ id: "Invest 500000 one time", value: "Invest at least $500,000 at one time." }, // removed on Tania's request
        { id: "Net assets of 2.5million", value: "A qualiﬁed accountant certiﬁes that the person has net assets of at least $2.5 million.  The certificate must not be more than 2 years old." },
        { id: "Gross income of last two years is 250000", value: "A qualiﬁed accountant certiﬁes that the person had a gross income for each of the last two ﬁnancial years of at least $250,000. The certificate must not be more than 2 years old." },
        { id: "Trust net assets of 2.5million", value: "The investor is a company or trust controlled by someone who has an accountant’s certificate certifying net assets of at least $2.5 million or gross income for each of the last two financial years of at least $250,000. The certificate must not be more than 2 years old." },
        { id: "Financial services licence", value: "The investor has a financial services licence." },
        { id: "AFSL holder considers sophisticated investor", value: "The AFSL holder considers the investor to be a sophisticated investor." },
        { id: "Cerificate will be provided later", value: "I don't have the certificate now. I will provide it later." }
    ];

    tableHeaderABNACN: string;

    constructor(public dialogRef: MatDialogRef<DialogComponent>, private router: Router, private service: ServiceForDialog, @Inject(MAT_DIALOG_DATA) public data: any, private companyWorkFlowService: CompanyWorkFlowService, private snackbar: SnackBarService, private commanService: CommanService) { }


    ngOnInit() {


        this.dialogId = this.data;

        if (this.data.DialogId === 6) {
            this.dialogId = this.data.DialogId;
            this.datasource = new MatTableDataSource<FundModel>();
            this.datasource = this.data.datasource;

        }

        if (this.data.DialogId === 7) {

            if (this.data.datasource.length == 0) {
                this.dialogRef.close();
                this.snackbar.openSnackBar("No existing application found for this fund.", 2);
                return;
            }
            this.dialogId = this.data.DialogId;
            this.datasource = new MatTableDataSource<ExistingApplicationModel>();
            this.datasource = this.data.datasource;

        }

        if (this.data.DialogId === 8) {
            this.dialogId = this.data.DialogId;
            this.tandc = this.data.tandc;

        }

        if (this.data.DialogId === 9) {
            this.dialogId = this.data.DialogId;
            this.privacyPolicy = this.data.privacyPolicy;
        }

        if (this.data.DialogId === 10) {
            this.dialogId = this.data.DialogId;
        }

        if (this.data.DialogId === 11) {
            this.dialogId = this.data.DialogId;
        }

        if (this.data.DialogId === 25) {
            this.dialogId = this.data.DialogId;
        }


        if (this.data.DialogId === 12) {
            this.displayedColumns = ['Key', 'Option'];
            this.dialogId = this.data.DialogId;
        }

        if (this.data.DialogId === 13) {
            debugger
            this.WorkFlowStep = this.data.WorkFlowStep;
            switch (this.data.WorkFlowStep) {
                case "Company":
                    this.text = "Company";
                    break;
                case "Partnership":
                    this.text = "Partnership";
                    break;
                case "OtherType-Person":
                    this.text = "Company";
                    break;
                case "OtherType-Corporate":
                    this.text = "Company";
                    break;
                //2,5 and 6, 7for both "" -Company-
                default:
                    break;
            }
            debugger
            this.appSummary = this.data.data;
            // if (this.appSummary.fundName.includes('Fund')) {
            // let isCheckFund = this.appSummary.fundName.split(" ").splice(-1);
            //this.appSummary.fundName = this.appSummary.fundName.replace("Fund", "")

            // this.isShowfundName = '';
            // }
            // else
            //     this.isShowfundName = 'Fund';

            if (this.appSummary.applicationPerson) {
                this.indiOrJoint = true;
                this.persondetails = this.appSummary.applicationPerson;
            }

            else if (this.appSummary.applicationOrganization) {

                if (this.appSummary.applicationOrganization.companyDetails) this.company = true;
                else if (this.appSummary.applicationOrganization.selfManagedSuperFund) this.selfManagedPerson = true;

                this.directorList = this.appSummary.applicationOrganization.applicationPerson.filter((x: any) => x.relationshipTypeName == "Director");

                this.shareholderlist = this.appSummary.applicationOrganization.applicationPerson.filter((x: any) => x.relationshipTypeName == "Shareholder");

                let directorSharePercentage = this.appSummary.applicationOrganization.applicationPerson.filter(x => x.relationshipTypeName == "Director" && + x.sharePercentage > 0);
                if (directorSharePercentage.length > 0) {
                    for (let index = 0; index < directorSharePercentage.length; index++) {
                        this.shareholderlist.push(directorSharePercentage[index]);
                    }
                }

                this.memberlist = this.appSummary.applicationOrganization.applicationPerson.filter((x: any) => x.relationshipTypeName == "Member");

                this.trusteelist = this.appSummary.applicationOrganization.applicationPerson.filter((x: any) => x.relationshipTypeName == "Trustee");

                this.partner = this.appSummary.applicationOrganization.applicationPerson.filter((x: any) => x.relationshipTypeName == "Partner");

                this.controllerOrBeneficial = this.appSummary.applicationOrganization.applicationPerson.filter((x: any) => x.relationshipTypeName == "Controller or Beneficial Owner");
            }

            if (this.appSummary.investorEligibility.sophisticatedInvestorEligibility) {
                if (this.eligibility.find((x: any) => x.id == this.appSummary.investorEligibility.sophisticatedInvestorEligibility) != undefined)
                    this.appSummary.investorEligibility.sophisticatedInvestorEligibility = this.eligibility.find((x: any) => x.id == this.appSummary.investorEligibility.sophisticatedInvestorEligibility).value;

            }

            this.dialogId = this.data.DialogId;

            if (this.data.ReadOnly)
                this.isShowTextNotReadOnly = false;

            else
                this.isShowTextNotReadOnly = true;
        }

        if (this.data.DialogId === 14) {
            this.dialogId = this.data.DialogId;
        }

        if (this.data.DialogId === 15) {
            this.dialogId = this.data.DialogId;
        }


        if (this.data.DialogId === 16) {
            this.dialogId = this.data.DialogId;
        }

        if (this.data.DialogId === 17) {
            this.dialogId = this.data.DialogId;
        }


        if (this.data.DialogId === 18) {


            this.getAddress = this.data.data;
            this.dialogId = this.data.DialogId;
        }

        if (this.data.DialogId === 19) {
            this.getAddress = this.data.data;
            this.dialogId = this.data.DialogId;
        }

        if (this.data.DialogId === 20) {
            this.getAddress = this.data.data;
            this.dialogId = this.data.DialogId;
        }

        if (this.data.DialogId === 21) {
            this.dialogId = this.data.DialogId;
        }

        if (this.data.DialogId == 22) {
            this.errorText = this.data.ErrorText;
            this.dialogId = this.data.DialogId;
        }

        if (this.data.DialogId == 24) {

            this.dialogId = this.data.DialogId;
        }

        if (this.data.DialogId == 23) {
            this.dialogId = this.data.DialogId;
        }

        if (this.data.DialogId == 26) {
            this.dialogId = this.data.DialogId;
        }

        if (this.data.DialogId == 27) {
            this.dialogId = this.data.DialogId;
        }

        if (this.data.DialogId == 28) {
            this.dialogId = this.data.DialogId;
        }

        if (this.data.DialogId == 29) {
            this.dialogId = this.data.DialogId;
        }

        if (this.data.DialogId == 30) {
            this.dialogId = this.data.DialogId;
        }
    }

    editAbovePage(step: string) {

        localStorage.setItem('WorkFlowStepCount', step);
        var applicationId = localStorage.getItem("ApplicationId");
        applicationId ? +applicationId : '';

        //let Workflow = 1;
        switch (this.WorkFlowStep) {
            case "Investor":
                this.router.navigateByUrl('/welcome', { skipLocationChange: true }).then(() =>
                    this.router.navigate(["individual/joint"]));
                break;
            case "Company":
                this.router.navigateByUrl('/welcome', { skipLocationChange: true }).then(() =>
                    this.router.navigate(["organisation"]));
                break;
            case "SelfManagedPerson":
                this.router.navigateByUrl('/welcome', { skipLocationChange: true }).then(() =>
                    this.router.navigate(["selfmanaged-person"]));
                break;
            case "SelfManagedCorporate":
                this.router.navigateByUrl('/welcome', { skipLocationChange: true }).then(() =>
                    this.router.navigate(["selfmanaged-corporate"]));
                break;
            case "OtherType-Person":
                this.router.navigateByUrl('/welcome', { skipLocationChange: true }).then(() =>
                    this.router.navigate(["other-person"]));
                break;
            case "OtherType-Corporate":
                this.router.navigateByUrl('/welcome', { skipLocationChange: true }).then(() =>
                    this.router.navigate(["other-corporate"]));
                break;
            case "Partnership":
                this.router.navigateByUrl('/welcome', { skipLocationChange: true }).then(() =>
                    this.router.navigate(["partner"]));
                break;
            default:
                break;
        }
    }

    continueExistingApplication(row: any) {
        localStorage.removeItem('AccountId');
        localStorage.removeItem('BizEntityId');
        localStorage.setItem('ApplicationId', row.applicationId);
        localStorage.setItem('FundName', row.name);
        let workflowNumber = row.workflowStep.split('W')[1];



        switch (workflowNumber) {
            case "1":
                this.router.navigateByUrl('/welcome', { skipLocationChange: true }).then(() =>
                    this.router.navigate(["individual/joint"]));
                break;
            case "2":

                this.router.navigateByUrl('/welcome', { skipLocationChange: true }).then(() =>
                    this.router.navigate(["organisation"]));
                break;
            case "3":

                this.router.navigateByUrl('/welcome', { skipLocationChange: true }).then(() =>
                    this.router.navigate(["selfmanaged-person"]));
                break;
            case "4":

                this.router.navigateByUrl('/welcome', { skipLocationChange: true }).then(() =>
                    this.router.navigate(["selfmanaged-corporate"]));
                break;
            case "5":

                this.router.navigateByUrl('/welcome', { skipLocationChange: true }).then(() =>
                    this.router.navigate(["other-person"]));
                break;
            case "6":

                this.router.navigateByUrl('/welcome', { skipLocationChange: true }).then(() =>
                    this.router.navigate(["other-corporate"]));
                break;
            case "7":

                this.router.navigateByUrl('/welcome', { skipLocationChange: true }).then(() =>
                    this.router.navigate(["partner"]));
                break;
            default:
                break;
        }



    }

    submitEmail(email: string) {

        if (!email || !(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/).test(email)) {
            this.snackbar.openSnackBar("Please enter a valid email address.", 2);
            return false;
        }
        this.showSpinner = true;
        this.commanService.submitEmailForExternalUser(email).subscribe(
            (data: any) => {
                if (data) {
                    this.showSpinner = false;
                    this.snackbar.openSnackBar("Email sent successfully.", 1);
                    this.dialogRef.close();
                }
            },
            (error: any) => {
                this.snackbar.openSnackBar(error.error, 2);
            }
        );
    }

    deleteExistingApplication(row: any) {
        this.applicationId = row.applicationId;
        this.dialogId = 17;
    }

    onCancel(): void {

        if (this.data.DialogId == 30) {
            this.dialogRef.close(false);
        }
        else
            this.dialogRef.close();
    }

    onYes() {
        if (this.data === 1) {
            this.router.navigate(['./login']);
            this.dialogRef.close();
        }
        if (this.data === 2) {
            this.service.dialogOnYes = 1;
            this.dialogRef.close();
        }
        if (this.data === 3) {
            this.service.dialogOnYes = 1;
            this.dialogRef.close();
        }
        if (this.data === 4) {
            this.router.navigate(['./login']);
            this.dialogRef.close();
        }
        if (this.data === 5) {
            this.router.navigate(['./get-funds']);
            this.dialogRef.close();
        }

        if (this.data.DialogId === 17) {
            if (this.applicationId == "0")
                this.applicationId = this.data.appId;
            else
                this.applicationId;
            this.companyWorkFlowService.deleteExistingApplication(this.applicationId).subscribe(
                (data: any) => {
                    let res = {
                        appId: this.applicationId,
                        data: data
                    }
                    this.dialogRef.close(res);
                }
            )
        }

        if (this.data.DialogId == 23) {
            this.dialogRef.close(true);
        }

        if (this.data.DialogId == 26) {
            this.dialogRef.close(true);
        }

        if (this.data.DialogId == 29) {
            this.dialogRef.close(true);
        }

        if (this.data.DialogId == 30) {
            this.dialogRef.close(true);
        }
    }

    apply(row: any) {
        localStorage.setItem("ProductId", row.productId);
        localStorage.setItem('FundName', row.productName);
        this.router.navigate(['./welcome']);
    }

    select(row: any) {
        localStorage.setItem('ABN', row.key);
        localStorage.setItem('CompanyName', row.value);
    }

    selectAddress(value: any) {
        this.dialogRef.close(value);
    }

    selectTelephone(value: any) {
        this.dialogRef.close(value);
    }


    onlineVerification() {
        this.dialogRef.close(true);
    }


    selectName(value: any) {
        this.dialogRef.close(value);
    }

    search(value: string) {
        debugger
        if (value.length > 0)
            this.showSpinner = true;
        else
            return;
        let checkNumberOrNot = /^\d+$/.test(value)
        //let checkNumberOrNot = this.commanService.OnlyNumbers(value);
        if (checkNumberOrNot) {
            if (value.length == 11) {
                this.tableHeaderABNACN = "ABN (Company Name)";
                this.companyWorkFlowService.getComanyNameByABN(value).subscribe(
                    (data: any) => {
                        this.showSpinner = false;
                        if (data.abn || data.acn || data.name) {
                            this.isDataAvailable = true;
                            this.datasource = new MatTableDataSource<ABNModel>();
                            this.datasource.data = JSON.parse('[{ "key":"' + data.abn + '", "value":"' + data.name + '" }]');
                        }
                        else
                            this.isDataAvailable = false;
                    },
                    (error: any) => {

                    });
            }
            else if (value.length == 9) {
                this.tableHeaderABNACN = "ACN (Company Name)";
                this.companyWorkFlowService.getComanyNameByACN(value).subscribe(
                    (data: any) => {
                        this.showSpinner = false;
                        if (data.abn || data.acn || data.name) {
                            this.isDataAvailable = true;
                            this.datasource = new MatTableDataSource<ABNModel>();
                            this.datasource.data = JSON.parse('[{ "key":"' + data.acn + '", "value":"' + data.name + '" }]');
                        }
                        else
                            this.isDataAvailable = false;
                    },
                    (error: any) => {

                    });
            }
            else {
                this.tableHeaderABNACN = "ABN (Company Name)";
                this.companyWorkFlowService.getAllCompanyName(value).subscribe(
                    (data: any) => {

                        this.showSpinner = false;
                        if (data.length > 0) {
                            this.isDataAvailable = true;
                            this.datasource = new MatTableDataSource<ABNModel>();
                            this.datasource.data = data;
                        }
                        else
                            this.isDataAvailable = false;
                    },
                    (error: any) => {
                        this.showSpinner = false;
                    }
                );
            }
        }


        else {
            this.tableHeaderABNACN = "ABN (Company Name)";
            this.companyWorkFlowService.getAllCompanyName(value).subscribe(
                (data: any) => {

                    this.showSpinner = false;
                    if (data.length > 0) {
                        this.isDataAvailable = true;
                        this.datasource = new MatTableDataSource<ABNModel>();
                        this.datasource.data = data;
                    }
                    else
                        this.isDataAvailable = false;
                },
                (error: any) => {
                    this.showSpinner = false;
                }
            );
        }
    }

    printModal() {

        let divContents = document.getElementById("printThis").innerHTML;

        var printWindow = window.open('', '_blank', 'left=400, top=200,height=700,width=800,visible=none');
        printWindow ? printWindow.document.write(
            `<html><head><title>Print APP Summary</title>
            <style>
            button{visibility:hidden}
            </style>
            `) : '';
        printWindow ? printWindow.document.write('</head><body>') : '';
        printWindow ? printWindow.document.write(divContents) : '';

        printWindow ? printWindow.document.write('</body></html>') : '';
        printWindow ? printWindow.document.close() : '';
        printWindow ? printWindow.focus() : '';
        printWindow ? printWindow.print() : '';
        printWindow ? printWindow.close() : '';
    }

    hideEditAboveButton() {
        var hide1 = document.getElementById("hide1");
        hide1 ? hide1.style.visibility = 'hidden' : '';
        var hide2 = document.getElementById("hide2");
        hide2 ? hide2.style.visibility = 'hidden' : '';
        var hide3 = document.getElementById("hide3");
        hide3 ? hide3.style.visibility = 'hidden' : '';
        var hide4 = document.getElementById("hide4");
        hide4 ? hide4.style.visibility = 'hidden' : '';
        var hide5 = document.getElementById("hide5");
        hide5 ? hide5.style.visibility = 'hidden' : '';
        var hide6 = document.getElementById("hide6");
        hide6 ? hide6.style.visibility = 'hidden' : '';
        var hide7 = document.getElementById("hide7");
        hide7 ? hide7.style.visibility = 'hidden' : '';
        var hide8 = document.getElementById("hide8");
        hide8 ? hide8.style.visibility = 'hidden' : '';
        var hide9 = document.getElementById("hide9");
        hide9 ? hide9.style.visibility = 'hidden' : '';
        var hide10 = document.getElementById("hide10");
        hide10 ? hide10.style.visibility = 'hidden' : '';
        var hide11 = document.getElementById("hide11");
        hide11 ? hide11.style.visibility = 'hidden' : '';
        var hide12 = document.getElementById("hide12");
        hide12 ? hide12.style.visibility = 'hidden' : '';
        var hide13 = document.getElementById("hide13");
        hide13 ? hide13.style.visibility = 'hidden' : '';
        var hide14 = document.getElementById("hide14");
        hide14 ? hide14.style.visibility = 'hidden' : '';
        var hide15 = document.getElementById("hide15");
        hide15 ? hide15.style.visibility = 'hidden' : '';
        var hide16 = document.getElementById("hide16");
        hide16 ? hide16.style.visibility = 'hidden' : '';
        var hide17 = document.getElementById("hide17");
        hide17 ? hide17.style.visibility = 'hidden' : '';
        var hide18 = document.getElementById("hide18");
        hide18 ? hide18.style.visibility = 'hidden' : '';
        var hide19 = document.getElementById("hide19");
        hide19 ? hide19.style.visibility = 'hidden' : '';
        var hide20 = document.getElementById("hide20");
        hide20 ? hide20.style.visibility = 'hidden' : '';
        var hide21 = document.getElementById("hide21");
        hide21 ? hide21.style.visibility = 'hidden' : '';
        var hide22 = document.getElementById("hide22");
        hide22 ? hide22.style.visibility = 'hidden' : '';
        var hide23 = document.getElementById("hide23");
        hide23 ? hide23.style.visibility = 'hidden' : '';
        var hide24 = document.getElementById("hide24");
        hide24 ? hide24.style.visibility = 'hidden' : '';
        var hide25 = document.getElementById("hide25");
        hide25 ? hide25.style.visibility = 'hidden' : '';
        var hide26 = document.getElementById("hide26");
        hide26 ? hide26.style.visibility = 'hidden' : '';
        var hide27 = document.getElementById("hide27");
        hide27 ? hide27.style.visibility = 'hidden' : '';
        var hide28 = document.getElementById("hide28");
        hide28 ? hide28.style.visibility = 'hidden' : '';
        var hide29 = document.getElementById("hide29");
        hide29 ? hide29.style.visibility = 'hidden' : '';
        var print = document.getElementById("print");
        print ? print.style.visibility = 'hidden' : '';
        var close = document.getElementById("close");
        close ? close.style.visibility = 'hidden' : '';

    }


}
