export class LogEntry {
    UserId: number = 0;
    Page: string = '';
    NowTime: Date = new Date();
    SessionTimeoutTime: Date = new Date();
    Browser: string = '';
    IpAddress: string = '';
    Url: string = '';
    Token: string = '';
    RefreshToken: '';
    ClientId: string = "101";
    ClientSecret: string = "970D8B7C-F389-471B-A723-A34794BC9F71";
    Scope: string = "openid profile";
}
