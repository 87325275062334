import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
    { path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule) },
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },

    { path: 'login/apply/continue/:applicationId', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    
    { path: 'login/apply/:managerOrganisationId/:productId', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },

    { path: 'login/sha', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: 'login/:adminusername/:username/:password', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: 'login/activate/:id', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: 'login/forgot', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },

    { path: 'register', loadChildren: () => import('./register/register.module').then(m => m.RegisterModule) },
    { path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },
    { path: 'forgot-password', loadChildren: () => import('./forgotpassword/forgotpassword.module').then(m => m.ForgotPasswordModule) },
    { path: 'forgot-username', loadChildren: () => import('./forgotusername/forgotusername.module').then(m => m.ForgotUsernameModule) },
    { path: 'register-holder', loadChildren: () => import('./layout/register-holder/register-holder.module').then(m => m.RegisterHolderModule) },
    { path: '**', redirectTo: 'not-found' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
