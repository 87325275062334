import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";


@NgModule({
    imports: [
        FormsModule,
        MatDialogModule
    ],
    providers: [],
})

export class DialogModule { }
