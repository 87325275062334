export class ValidationFieldNameList {

    FieldNameList = [
        {
            FieldName: 'titleId',
            EndUserFieldName: 'Select Title',
            Message: 'Title is required',
        },
        {
            FieldName: 'firstName',
            EndUserFieldName: 'First Name',
            Message: 'First Name is required',
        },
        {
            FieldName: 'lastName',
            EndUserFieldName: 'Last Name',
            Message: 'Last Name is required',
        }
        ,
        {
            FieldName: 'austAddress',
            EndUserFieldName: 'Address',
            Message: 'Address (Lookup) is required',
        },
        {
            FieldName: 'telephoneMobile',
            EndUserFieldName: 'Mobile',
            Message: 'Telephone (Mobile) is required',
        },
        {
            FieldName: 'email',
            EndUserFieldName: 'Email',
            Message: 'Email is required',
        },
        {
            FieldName: 'australiataxRadioVal',
            EndUserFieldName: 'Is this individual a tax resident of Australia',
            Message: 'Required: Is Australian tax resident?',
        },
        {
            FieldName: 'pepRadioVal',
            EndUserFieldName: 'Is this individual a PEP?',
            Message: 'Required: Is this individual a PEP?',
        },
        {
            FieldName: 'usRadioVal',
            EndUserFieldName: 'Is this individual a US citizen or US resident for tax purposes?',
            Message: 'Required: Is US tax resident?',
        },
        // {
        // FieldName: 'declare',
        // EndUserFieldName: 'I declare the above statements to be true and correct',
        // Message: 'I declare the above statements to be true and correct is required',
        // }

        {
            FieldName: 'streetNumber',
            EndUserFieldName: 'Street Number',
            Message: 'Street Number is required'
        },
        {
            FieldName: 'streetName',
            EndUserFieldName: 'Street Name',
            Message: 'Street Name is required'
        },
        {
            FieldName: 'streetType',
            EndUserFieldName: 'Street Type',
            Message: 'Street Type is required'
        },
        {
            FieldName: 'suburbtowncity',
            EndUserFieldName: 'Surburb, town or city',
            Message: 'Surburb, town or city is required'
        },
        {
            FieldName: 'postZipCode',
            EndUserFieldName: 'Postcode',
            Message: 'Postcode is required'
        },
        {
            FieldName: 'state',
            EndUserFieldName: 'State',
            Message: 'State is required'
        },
        {
            FieldName: 'addressLine1',
            EndUserFieldName: 'Address Line 1',
            Message: 'Address Line 1 is required'
        },
        {
            FieldName: 'addressLine2',
            EndUserFieldName: 'Address Line 2',
            Message: 'Address Line 2 is required'
        },
        {
            FieldName: 'pepDesc',
            EndUserFieldName: 'Please provide description of PEPs position',
            Message: "PEP's description is required"
        },
        {
            FieldName: 'tinText',
            EndUserFieldName: 'Please provide the Taxpayer Identification Number (TIN)',
            Message: 'TIN is required'
        },
        {
            FieldName: 'TaxationCountry1Id',
            EndUserFieldName: 'Country name 1',
            Message: 'Country Name 1 is required'
        },
        {
            FieldName: 'TaxationCountry2Id',
            EndUserFieldName: 'Country name 2',
            Message: 'Country Name 2 is required'
        },
        {
            FieldName: 'TaxationCountry3Id',
            EndUserFieldName: 'Country name 3',
            Message: 'Country Name 3 is required'
        },
        {
            FieldName: 'tinradiosOne',
            EndUserFieldName: 'Select reason for no TIN 1',
            Message: 'No TIN reason for Country 1 is required'
        },
        {
            FieldName: 'tinradiosTwo',
            EndUserFieldName: 'Select reason for no TIN 2',
            Message: 'No TIN reason for Country 2 is required'
        },
        {
            FieldName: 'tinradiosThree',
            EndUserFieldName: 'Select reason for no TIN 3',
            Message: 'No TIN reason for Country 3 is required'
        },
        {
            FieldName: 'tincountryOne',
            EndUserFieldName: 'Does this individual have a Tax Identification Number (TIN) for this country? 1',
            Message: 'Does this individual have a Tax Identification Number (TIN) for this country? 1 is required'
        }
        ,
        {
            FieldName: 'tincountryTwo',
            EndUserFieldName: 'Does this individual have a Tax Identification Number (TIN) for this country? 2',
            Message: 'Does this individual have a Tax Identification Number (TIN) for this country? 2 is required'
        },
        {
            FieldName: 'tincountryThree',
            EndUserFieldName: 'Does this individual have a Tax Identification Number (TIN) for this country? 3',
            Message: 'Does this individual have a Tax Identification Number (TIN) for this country? 3 is required'
        },
        {
            FieldName: 'streetNumber_mail',
            EndUserFieldName: 'Mailing Street Number',
            Message: 'Mailing Street Number'
        },
        {
            FieldName: 'streetName_mail',
            EndUserFieldName: 'Mailing Street Name',
            Message: 'Mailing Street Name is required'
        },
        {
            FieldName: 'suburbtowncity_mail',
            EndUserFieldName: 'Mailing Surburb, town or city',
            Message: 'Mailing Surburb, town or city is required'
        },
        {
            FieldName: 'postZipCode_mail',
            EndUserFieldName: 'Mailing Postcode',
            Message: 'Mailing Postcode is required'
        },
        {
            FieldName: 'state_mail',
            EndUserFieldName: 'Mailing State',
            Message: 'Mailing State is required'
        },
        {
            FieldName: 'austMalingAddress',
            EndUserFieldName: 'Mailing Address',
            Message: 'Mailing Address is required'
        },
        {
            FieldName: 'mailingAddressLine1',
            EndUserFieldName: 'Mailing Address 1',
            Message: 'Mailing Address 1 is required'
        },
        {
            FieldName: 'mailingAddressLine2',
            EndUserFieldName: 'Mailing Address 2',
            Message: 'Mailing Address 2 is required'
        }
    ];
}