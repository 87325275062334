import { Injectable, EventEmitter, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UpdateDetails } from 'src/app/shared/models/UpdateDetailsModel';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { Observable } from 'rxjs';
import { CRSFATCA } from './crs/CrsModel';


@Injectable()
export class UpdateDetailsService {

    baseURL: string;
    userData: any;
    // reqHeader: any = new HttpHeaders({ 'x-api-version': '3.0.Admin' });

    constructor(private _httpClient: HttpClient, @Inject(SESSION_STORAGE) private storage: StorageService) {
        //this.baseURL = environment.baseApiUrl + 'portal/';
        if (sessionStorage.getItem('baseURL'))
            this.baseURL = sessionStorage.getItem('baseURL') + 'portal/';
        this.userData = this.storage.get('UserToken');
    }

    getUpdateDetailsAndPreferences(accountId: string, instanceId: string) {
        var params = new HttpParams().set('accountId', accountId);
        params = params.set('instanceId', instanceId);
        return this._httpClient.get(this.baseURL + 'GetUpdateDetailsAndPreferences', { params: params });
    }

    getEmailCompRef() {
        var params = new HttpParams();
        let bizEntityId = localStorage.getItem("BizEntityId")
        let applicationId = localStorage.getItem("ApplicationId")
        if (localStorage.getItem("BizEntityId"))
            params = params.set('bizEntityId', bizEntityId ? bizEntityId : '');
        else {
            params = params.set('applicationId', applicationId ? applicationId : '');
            this.baseURL = this.baseURL.replace('portal', 'oa');
        }
        return this._httpClient.get(this.baseURL + 'GetEmailCompRef', { params: params });
    }

    getEmail() {
        let bizEntityId = localStorage.getItem("BizEntityId")
        var params = new HttpParams().set('bizEntityId', bizEntityId ? bizEntityId : '');
        return this._httpClient.get(this.baseURL + 'GetEmail', { params: params });
    }

    getAddress() {
        let bizEntityId = localStorage.getItem("BizEntityId")
        var params = new HttpParams().set('bizEntityId', bizEntityId ? bizEntityId : '');
        return this._httpClient.get(this.baseURL + 'GetAddress', { params: params });
    }

    getAddressForUnlisted() {
        let bizEntityId = localStorage.getItem("BizEntityId")
        var params = new HttpParams().set('bizEntityId', bizEntityId ? bizEntityId : '');
        return this._httpClient.get(this.baseURL + 'GetAddressForUnlisted', { params: params });
    }

    getPhone() {
        let bizEntityId = localStorage.getItem("BizEntityId")
        var params = new HttpParams().set('bizEntityId', bizEntityId ? bizEntityId : '');
        return this._httpClient.get(this.baseURL + 'GetPhone', { params: params });
    }

    getTFNABN() {
        let bizEntityId = localStorage.getItem("BizEntityId")
        var params = new HttpParams().set('bizEntityId', bizEntityId ? bizEntityId : '');
        return this._httpClient.get(this.baseURL + 'GetTFNABN', { params: params });
    }

    getTaxationDetails() {
        let bizEntityId = localStorage.getItem("BizEntityId")
        var params = new HttpParams().set('bizEntityId', bizEntityId ? bizEntityId : '');
        return this._httpClient.get(this.baseURL + 'GetTaxationDetails', { params: params });
    }

    getPayment() {
        let bizEntityId = localStorage.getItem("BizEntityId")
        var params = new HttpParams().set('bizEntityId', bizEntityId ? bizEntityId : '');
        return this._httpClient.get(this.baseURL + 'GetPayment', { params: params });
    }

    getDRP() {
        let bizEntityId = localStorage.getItem("BizEntityId")
        var params = new HttpParams().set('bizEntityId', bizEntityId ? bizEntityId : '');
        return this._httpClient.get(this.baseURL + 'GetDRP', { params: params });
    }

    getPassword() {
        var params = new HttpParams().set('userId', this.storage.get("UserToken").userID);
        return this._httpClient.get(this.baseURL + 'GetPassword', { params: params });
    }

    saveDetails(model: UpdateDetails) {
        debugger;
        let bizEntityId = localStorage.getItem("BizEntityId")
        let accountId = localStorage.getItem("AccountId")
        model.InstanceID = +this.storage.get('instanceId'); // this.userData.instanceId;
        model.UserID = this.storage.get('UserToken').userID;
        model.BizEntityID = bizEntityId ? +bizEntityId == 0 ? 1 : +bizEntityId : model.BizEntityID != 0 ? model.BizEntityID : 0;
        model.AccountID = accountId ? +accountId == 0 ? 1 : +accountId : model.AccountID != 0 ? model.AccountID : 0;
        var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this._httpClient.post(this.baseURL + model.Endpoint, model, { headers: reqHeader });
    }

    getAllPhones(bizEntityId: string) {
        var params = new HttpParams().set('bizEntityId', bizEntityId);
        return this._httpClient.get(this.baseURL + 'GetAllPhones', { params: params });
    }

    getStates(countryCode: string) {
        var params = new HttpParams().set('countryCode', countryCode);
        return this._httpClient.get(this.baseURL + 'GetStates', { params: params });
    }

    public registerCRS = (model: CRSFATCA) => {
        var crsbaseURL;

        crsbaseURL = localStorage.getItem('baseURL'); + 'crs/';

        var reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this._httpClient.post(crsbaseURL + 'SaveCRSDetail', model, { headers: reqHeader });
        //return this._httpClient.post(this.baseURL.replace(this.baseURL, "crs") + 'crs', model);
    }

    public GetCRSDetails(LoggedInUserId: any, BizEntityId: any): Observable<any> {
        return this._httpClient.get<CRSFATCA>(this.baseURL.replace(this.baseURL, "crs") + 'getcrsdetails?LoggedInUserId=' + LoggedInUserId + '&BizEntityId=' + BizEntityId, LoggedInUserId);
    }

    public GetPartyDetails(BizEntityid: any) {
        return this._httpClient.get(this.baseURL.replace(this.baseURL, "crs") + 'getpartydetails?BizEntityid=' + BizEntityid)
    }
}
