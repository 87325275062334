import { Component, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LoginService } from './login/login.service';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

import { GetIpService } from './shared/services/ipservice';
import { HttpClient } from '@angular/common/http';




@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    cssUrl: any;
    isApiLoaded = false;

    constructor(private httpClient: HttpClient, private sanitizer: DomSanitizer, private loginServiceOb: LoginService, @Inject(SESSION_STORAGE) private storage: StorageService, private getIpService: GetIpService) {
        this.fetchDatas();
    }


    getIP() {
        this.getIpService.getIPAddress().subscribe((res: any) => {
            localStorage.setItem("IpAddress", res.ip);
        });
    }

    fetchDatas(): any {

        var promise = this.httpClient.get("assets/appsettings.json").toPromise();
        promise.then((data: any) => {
            sessionStorage.setItem('baseURL', data.baseApiUrl);
            this.InitializeAngularApp();

        }).catch((error) => {
            console.log("Promise rejected with " + JSON.stringify(error));
        });
    }




    InitializeAngularApp() {

        this.getIP();
        this.sanitizer.bypassSecurityTrustResourceUrl('../styles/style.css');
        var host = window.location.hostname;
        //if (!environment.production)
        switch (host.substr(0, 3)) {
            // case "tes":
            //     host = host.replace('test-', '');
            //     break;
            // case "dev":
            //     host = host.replace('dev-', '');
            //     break;
            // case "lab":
            //     host = host.replace('lab-', '');
            //     break;
            // case "dem":
            //     host = host.replace('demo-', '');
            //     break;
            case "loc":
                host = host.replace('localhost', 'dev-xcend-portal.7G.com.au');
                // host = host.replace('localhost', 'dev-seveng-portal.7g.com.au');
                break;
        }

        this.loginServiceOb.getStylesheet(host)
            .subscribe(
                (data: any) => {
                    this.isApiLoaded = true;
                    this.storage.set('displayColumnsInTransactions', data?.displayColumnsInTransactions ?? "type");
                    if (this.storage.get('stylesheet') && data.stylesheet == this.storage.get('stylesheet') && data.instanceId == this.storage.get('instanceId')) {
                        this.storage.set('stylesheet', data.stylesheet);
                        this.storage.set('instanceId', data.instanceID);
                        this.storage.set('HeadTitle', data.headTitle);
                        this.storage.set('allowRegisterUser', data.allowRegisterUser);
                        this.cssUrl = '../assets/' + data.stylesheet;
                        this.cssUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.cssUrl);
                    }
                    else if (this.storage.get('stylesheet')) {
                        this.storage.set('stylesheet', this.storage.get('stylesheet'));
                        this.storage.set('instanceId', this.storage.get('instanceId'));
                        this.storage.set('HeadTitle', this.storage.get('HeadTitle'));
                        this.storage.set('allowRegisterUser', data.allowRegisterUser);
                        this.cssUrl = '../assets/' + this.storage.get('stylesheet');
                        this.cssUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.cssUrl);
                    }
                    else {
                        this.storage.set('stylesheet', data.stylesheet);
                        this.storage.set('instanceId', data.instanceID);
                        this.storage.set('HeadTitle', data.headTitle);
                        this.storage.set('allowRegisterUser', data.allowRegisterUser);
                        this.cssUrl = '../assets/' + data.stylesheet;
                        this.cssUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.cssUrl);
                    }
                },
                (error: any) => {
                    console.log("API Initialization Error - " + error.error);
                }
            );
    }
}
